import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  async fetchOpportunities(query) {
    if (query.length >= 2) {
      const url = `/salesforce.json?query=${query}`;
      let csrf = document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content");
      try {
        const response = await fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf,
          },
        });
        const data = await response.json();

        if (data.error) throw new Error(data.error);
        if (data.data.length > 0) {
          document.getElementById("sf-search-results").style.display = "block";
          let results = data.data
            .filter((opportunity) => {
              return opportunity.name.toLowerCase().includes(query.toLowerCase());
            })
            .map((opportunity) => {
              return `<li data-action="click->auto-complete#handleSelect touch->auto-complete#handleSelect keydown.enter->auto-complete#handleSelect" value="${opportunity.opportunity_number}" class="dropdown-item cursor-pointer hover:text-dark_green hover:font-bold hover:underline focus-visible:text-dark_green focus-visible:outline-none focus-visible:font-bold focus-visible:underline dark:hover:text-light_green dark:focus-visible:text-light_green" tabIndex="0" >
                      ${opportunity.name}
                    </li>`;
            })
            .join("");

          document.getElementById("sf-search-results").innerHTML = results;
        } else {
          document.getElementById("sf-search-results").style.display = "none";
          document
            .getElementById("salesforce_opportunity_id")
            .setAttribute("value", "");
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    else {
      document.getElementById("sf-search-results").style.display = "none";
      document
        .getElementById("salesforce_opportunity_id")
        .setAttribute("value", "");
    }
  }

  handleSelect(e) {
    const id = e.target.attributes.value.nodeValue,
      name = e.target.innerText;
    document
      .getElementById("salesforce_opportunity_id")
      .setAttribute("value", id);
    document.getElementById("salesforce_opportunity_name").value = name;
    document.getElementById("sf-search-results").style.display = "none";
  }

  connect() {
    document.getElementById("sf-search-results").style.display = "none";

    document.getElementById("salesforce_opportunity_name").addEventListener("input", (e) => {
      this.fetchOpportunities(e.target.value);
    });
  }
}
