import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "password", "button", "errorMessage"];

  connect() {
    this.checkEmail();
  }

  checkEmail() {
    const emailValue = this.emailTarget.value;
    if (emailValue.includes('valleyforge')) {
      if (this.hasPasswordTarget) {
      this.passwordTarget.disabled = true;
      }
      this.buttonTarget.disabled = true;
      this.errorMessageTarget.classList.remove("hidden");
    } else {
      if (this.hasPasswordTarget) {
      this.passwordTarget.disabled = false;
      }
      this.buttonTarget.disabled = false;

      this.errorMessageTarget.classList.add("hidden");
    }
  }
}